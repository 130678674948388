import Vue from "vue";

let timer = null;
export let itf = {
  fixer: function (el) {
    if (el.classList.contains("ivu-table-wrapper")) {
      let tbls = el.getElementsByClassName("ivu-table");
      if (tbls.length > 0) {
        let hdrs = tbls[0].getElementsByClassName("ivu-table-header");
        let bdys = tbls[0].getElementsByClassName("ivu-table-body");
        let fixedRight = tbls[0].getElementsByClassName("ivu-table-fixed-right");

        if (bdys.length > 0) {
          let b = bdys[0];

          let xscroll = b.scrollWidth > b.clientWidth;
          let yscroll = b.scrollHeight > b.clientHeight;

          if (xscroll) {
            let xw = b.offsetWidth - b.clientWidth;

            if (fixedRight.length > 0) {
              fixedRight[0].style.bottom = `${xw}px`;
            }
          }

          if (yscroll) {
            let yw = b.offsetHeight - b.clientHeight;

            if (hdrs.length > 0) {
              hdrs[0].style.overflowY = `scroll`;
            }

            if (fixedRight.length > 0) {
              fixedRight[0].style.right = `${yw}px`;
            }
          }
        }
      }
    }
  },
  inserted: function (el, binding) {
    timer = setTimeout(() => itf.fixer(el, binding), 250); //
  },
  update: function (el, binding) {
    itf.fixer(el, binding);
  },
  unbind: function () {
    clearTimeout(timer);
  },
};

Vue.directive("ivu-table-fixer", itf);

Vue.directive("truncate-text-1-line", {
  inserted: function (el) {
    const maxLines = 1;
    const lineHeight = parseFloat(getComputedStyle(el).lineHeight);
    const maxHeight = maxLines * lineHeight;
    el.style.maxHeight = `${maxHeight}px`;
    el.style.overflow = "hidden";
    el.style.textOverflow = "ellipsis";
    el.style.display = "-webkit-box";
    el.style.WebkitBoxOrient = "vertical";
    el.style.whiteSpace = "normal";
  },
});

Vue.directive("truncate-text-2-lines", {
  inserted: function (el) {
    const maxLines = 2;
    const lineHeight = parseFloat(getComputedStyle(el).lineHeight);
    const maxHeight = maxLines * lineHeight;
    el.style.maxHeight = `${maxHeight}px`;
    el.style.overflow = "hidden";
    el.style.textOverflow = "ellipsis";
    el.style.display = "-webkit-box";
    el.style.WebkitBoxOrient = "vertical";
    el.style.whiteSpace = "normal";
  },
});
