// eslint-disable
// this is an auto generated file. This will be overwritten

export const onNewHealthEvent = `subscription OnNewHealthEvent {
  onNewHealthEvent {
    arn
    service
    eventTypeCode
    eventTypeCategory
    region
    statusCode
    startTime
    endTime
    lastUpdatedTime
    latestDescription
  }
}
`;

export const onUpdateLivelookContacts = `subscription onUpdateLivelookContacts($InstanceId: String!) {
  onUpdateLivelookContacts(InstanceId: $InstanceId) {
    InstanceId
    id
    Action
    QueueName
    QueueArn
    EnqueueTimestamp
    Channel
    CustomerEndpoint
    CustomerName
    CustomerId
  }
}
`;

export const onNewConversationMessage = `subscription onNewConversationMessage($InstanceId: String!, $ConversationId: String!) {
  onNewConversationMessage(InstanceId: $InstanceId, ConversationId: $ConversationId) {
    InstanceId
    Author
    id
    Body
    BodyUrl
    BodyId,
    MediaUrls {
      ContentType
      Url
    }
    NumMedia
    NumSegments
    Direction
    CreatedAt
    Subject
    From
    To
    Cc
    Bcc
    ConversationId
  }
}`;

export const onLogin = `subscription onLogin($InstanceId: String!, $Username: String!) {
  onLogin(InstanceId: $InstanceId, Username: $Username) {
    Username
    InstanceId
    HeartBeat
    Platform
  }
}`;

export const onNewReport = `subscription onNewReport($InstanceId: String!, $Owner: String) {
  onNewReport(InstanceId: $InstanceId, Owner: $Owner) {
    id
    Owner
    Type
    InstanceId
    RelativeTime
    Parent
    NextRunTime
    ParentId
    CreatedAt
    RunNow
    CompletedAt
    Units
    Name
    RequestedAt
    ChildReports {
      id
      CompletedAt
      Status
      Location
      Owner
      DateRange
      Filters
    }
    Status
    Data
    Filters
    DateRange
    Scheduled
    Schedule {
      Frequency
      DeliveryEmails
    }
  }
}`;

export const onFinishedReport = `subscription onFinishedReport($InstanceId: String!, $Owner: String) {
  onFinishedReport(InstanceId: $InstanceId, Owner: $Owner) {
    id
    Owner
    InstanceId
    Error
    RelativeTime
    NextRunTime
    Units
    ChildReports {
      id
      CompletedAt
      Status
      Name
      Language
      Location
      Owner
      DateRange
    }
    Parent
    ParentId
    CompletedAt
    Scheduled
    Schedule {
      Frequency
      DeliveryEmails
    }
    RunNow
    CreatedAt
    Name
    Language
    RequestedAt
    Status
    Type
    Data
    Filters
    DateRange
  }
}`;

export const onGlobalContact = `subscription onGlobalContact($InstanceId: String, $IsGlobal: Boolean) {
  onGlobalContact(InstanceId: $InstanceId, IsGlobal: $IsGlobal) {
    InstanceId
    UserId
    Id
    Name
    Email
    WorkPhone
    HomePhone
    CellPhone
    IsGlobal
    IsFavorite
  }
}`;

export const onDeleteGlobalContact = `subscription onDeleteGlobalContact($InstanceId: String, $IsGlobal: Boolean) {
  onDeleteGlobalContact(InstanceId: $InstanceId, IsGlobal: $IsGlobal) {
    InstanceId
    UserId
    Id
    Name
    Email
    WorkPhone
    HomePhone
    CellPhone
    IsGlobal
    IsFavorite
  }
}`;

export const onUpdateQueue = `subscription onUpdateQueue($Arn: String!) {
  onUpdateQueue(Arn: $Arn) {
    id
    Arn
    Action
    Name
    QueueId
    QueueArn
    Channel
    Contacts {
      InitiationTimestamp
      id
      InitiationMethod
      CustomerEndpoint
      Channel
      Queue
    }
    ReportRange {
      end
      start
    }
  }
}
`;

export const onOfferedMonitor = `subscription OnOfferedMonitor($InstanceId: String!, $Username: String!) {
  onOfferedMonitor(InstanceId: $InstanceId, Username: $Username) {
    id
    InstanceId
    Username
    OfferSDP
    AnswerSDP
    Initiator
  }
}
`;

export const onOfferedVoiceCall = `subscription OnOfferedVoiceCall($InstanceId: String!, $Username: String!) {
  onOfferedVoiceCall(InstanceId: $InstanceId, Username: $Username) {
    id
    InstanceId
    Username
    OfferSDP
    AnswerSDP
    Initiator
  }
}
`;

export const onAnsweredMonitor = `subscription OnAnsweredMonitor($id: String) {
  onAnsweredMonitor(id: $id) {
    id
    InstanceId
    Username
    OfferSDP
    AnswerSDP
    Initiator
  }
}
`;

export const onAnsweredVoiceCall = `subscription OnAnsweredVoiceCall($id: String) {
  onAnsweredVoiceCall(id: $id) {
    id
    InstanceId
    Username
    OfferSDP
    AnswerSDP
    Initiator
  }
}
`;

export const onNewDisposition = `subscription onNewDisposition($InstanceId: String!) {
  onNewDisposition(InstanceId: $InstanceId) {
    id
    InstanceId
    Queues {
      id
      Arn
      Name
    }
    Name
  }
}
`;

export const onNewAnnouncement = `subscription OnNewAnnouncement($InstanceId: String) {
  onNewAnnouncement(InstanceId: $InstanceId) {
    id
    Message
    InstanceId
    From
    Urgency
    Queues
    Title
    Date
    DeliveryMethods
  }
}`;

export const onNeedsHelp = `subscription OnNeedsHelp ($InstanceId: String) {
  onNeedsHelp(InstanceId: $InstanceId) {
    NeedsHelp
    InstanceId
    Username
    Id
  }
}`;

export const onCreateUser = `subscription OnCreateUser($InstanceId: String) {
  onCreateUser(InstanceId: $InstanceId) {
    AWSAccountID
    Password
    InstanceId
    InstanceAlias
    Id
    Arn
    FirstName
    Region
    LastName
    Username
    StatusName
    Email
    StatusDuration
    Role
    Permissions
    IdentityInfo {
      FirstName
      LastName
      Email
    }
    PhoneConfig {
      PhoneType
      AutoAccept
      AfterContactWorkTimeLimit
      DeskPhoneNumber
    }
    SecurityProfile {
      id
      Name
      Description
      SoftPhone
      PermissionGroups {
        Name
        Order
        PermissionItems {
          Action
          Name
          Values {
            All
            View
            Edit
            Create
            Remove
            Listen
            Download
            Inbound
            Outbound
            Enable
            Share
          }
        }
      }
    }
    DirectoryUserId
    SecurityProfileId
    SecurityProfileIds
    RoutingProfileId {
      Name
      Arn
      Id
    }
    Preferences {
      Avatar
      PrimaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRinger {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerEmail {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      PrimaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      SecondaryRingerSMS {
        Sound
        isEnabled
        DeviceId
        Volume
      }
      AlertTone
      DeviceId
      Theme
      DashboardCards {
        colOne {
          component
          id
          isShow
          name
        }
        colTwo {
          component
          id
          isShow
          name
        }
        colThree {
          component
          id
          isShow
          name
        }
      }
    }
  }
}
`;
export const onUpdateUser = `subscription OnUpdateUser($InstanceId: String) {
  onUpdateUser(InstanceId: $InstanceId) {
    Platform
    isBeingCoached
    isBeingMonitored
    isConnectedToWebRTCCall
    StatusStartTimestamp
    Id
    Username
    Contact
    StatusName
    StatusDuration
    StatusType
    NeedsHelp
    SecurityProfileId
    HeartBeat
    IdentityInfo {
      FirstName
      LastName
      Email
    }
    PhoneConfig {
      PhoneType
      AutoAccept
      AfterContactWorkTimeLimit
      DeskPhoneNumber
    }
    DirectoryUserId
    RoutingProfileId {
      Name
      Arn
      Id
    }
  }
}
`;

export const onUpdateUsers = `subscription OnUpdateUsers($InstanceId: String!) {
  onUpdateUsers(InstanceId: $InstanceId) {
    InstanceId
    items {
      Platform
      isBeingCoached
      isBeingMonitored
      isConnectedToWebRTCCall
      StatusStartTimestamp
      Id
      Username
      Contact
      Contacts
      StatusName
      StatusDuration
      StatusType
      NeedsHelp
      SecurityProfileId
      HeartBeat
      IdentityInfo {
        FirstName
        LastName
        Email
      }
      PhoneConfig {
        PhoneType
        AutoAccept
        AfterContactWorkTimeLimit
        DeskPhoneNumber
      }
      DirectoryUserId
      Queues
      RoutingProfileId {
        Name
        Arn
        Id
      }
    }
  }
}`;
export const onNewActivityCustomer = `subscription OnNewActivity(
  $CustomerEndpoint: String,
  $InstanceId: String
) {
onNewActivity(
  CustomerEndpoint: $CustomerEndpoint,
  InstanceId: $InstanceId
) {
  id
  InstanceId
  UserId
  AWSAgentID
  AWSAccountID
  Channel
  InitiationMethod
  Username
  CustomerEndpoint
  SystemEndpoint
  InitiationTimestamp
  DisconnectTimestamp
  Queue
  Connections
}
}
`;
export const onNewActivity = `subscription OnNewActivity(
  $Username: String,
  $InstanceId: String
  $Queues: [String]
) {
  onNewActivity(
    Username: $Username,
    InstanceId: $InstanceId,
    Queues: $Queues
  ) {
    id
    InstanceId
    Username
    AWSAgentID
    AWSAccountID
    Channel
    InitiationMethod
    CustomerEndpoint
    SystemEndpoint
    InitiationTimestamp
    DisconnectTimestamp
    Queue
    Connections
    range
  }
}
`;
export const onUpdateSecurityProfile = `subscription onUpdateSecurityProfile($InstanceId: String!, $id: String!) {
  onUpdateSecurityProfile(InstanceId: $InstanceId, id: $id){
    InstanceId
    id
    Realm
  	Name
    Description
    isDefault
    SoftPhone
    PermissionGroups {
      Name
      Order
      PermissionItems {
        Action
        Name
        Values {
          All
          View
          Edit
          Create
          Remove
          Listen
          Download
          Inbound
          Outbound
          Enable
          Share
        }
      }
    }
  }
}
`;
export const onUpdateUserSecurityProfile = `subscription onUpdateUserSecurityProfile($InstanceId: String!, $Username: String!) {
  onUpdateUserSecurityProfile(InstanceId: $InstanceId, Username: $Username){
    InstanceId
    Username
    SecurityProfile {
      id
      Name
      Description
      SoftPhone
      PermissionGroups {
        Name
        Order
        PermissionItems {
          Action
          Name
          Values {
            All
            View
            Edit
            Create
            Remove
            Listen
            Download
            Inbound
            Outbound
            Enable
            Share
          }
        }
      }
    }
  }
}
`;

export const onDestroyedVoiceCall = `subscription onDestroyedVoiceCall($id: String!) {
  onDestroyedVoiceCall(id: $id){
    id
    InstanceId
    Username
  }
}
`;

export const onUpdateSettings = `subscription onUpdateSettings($InstanceId: String!) {
  onUpdateSettings(InstanceId: $InstanceId){
    InstanceId
    Theme
    MultiLevelSeries
    PresentedNotAnswered {
      Status
      Label
      Duration
    }
    CallDispositions {
      isEnabled
      allowCustomInput
      isMandatory
      isMultiLevelMandatory
    }
    ChangeOutboundQueues {
      isEnabled
      Queues
    }
    SAML {
      isEnabled
      loginUrl
    }
    LiveLook {
      isEnabled
    }
    Reporting {
      isEnabled
      isAllowed
    }
    ContactLens {
      isAllowed
      isEnabled
    }
    DefaultCountry {
      Name
      ISO
    }
    OutboundMessages {
      isEnabled
      ContactFlow
    }
    ThirdPartyIntegrations
    DefaultOutboundChannels {
      EmailChannelId
      SmsChannelId
    }
    DefaultContactFlow {
      FlowId
      FlowName
    }
    IdleActive
    IdleStatus
    IdleTime
    LegacyWebhookChannelBehavior
    ComprehendIntegration
    NewStatusBar
    DefaultTaskTemplateId
    QuickSightReadOnlyUser
    QuickSightPricing
    EmailAliases {
      Id
      EmailAddress
    }
    ContactAttributesFilter {
      Id
      ContactAttributeKey
    }
  }
}
`;

export const onUpdateAccount = `subscription onUpdateAccount ($InstanceId: String!) {
  onUpdateAccount(InstanceId: $InstanceId){
    InstanceId
    AdminLogo
		LogoMediaURL
    AdminName
    AdminMail
    CompanyName
    CognitoPoolId
    AWSAccountID
    InstanceAlias
    Region
    AccessKeyId
    RoleArn
    SecretAccessKey
    LastCtrEvent
    LastAgentEvent
    LastContactEvent
    DomainName
    BucketName
    TimeZone {
      value
      abbr
      isdst
      text
      offset
      utc
    }
  }
}
`;

export const onNewSmsMessage = `subscription onNewSmsMessage($InstanceId: String!, $ContactId: String!) {
    onNewSmsMessage(InstanceId: $InstanceId, ContactId: $ContactId) {
      InstanceId
    }
  }
`;

export const onNewSmsContact = `subscription onNewSmsContact($InstanceId: String!, $Username: String!) {
    onNewSmsContact(InstanceId: $InstanceId, Username: $Username) {
      InstanceId
    }
  }
`;

export const onStatusChangeSmsContact = `subscription onStatusChangeSmsContact($InstanceId: String!, $id: String!) {
  onStatusChangeSmsContact(InstanceId: $InstanceId, id: $id) {
    InstanceId
      id
      Status
  }
}`;

export const newAgentChatParticipantEvent = `subscription newAgentChatParticipantEvent($InstanceId: String!, $Username:String!) {
  newAgentChatParticipantEvent(InstanceId: $InstanceId, Username: $Username) {
    InstanceId
    Username
    ConversationId
  }
}`;

export const newAgentChatMessageEvent = `subscription newAgentChatMessageEvent($InstanceId: String!, $ConversationId:String!) {
  newAgentChatMessageEvent(InstanceId: $InstanceId, ConversationId: $ConversationId) {
    InstanceId
    Author
    id
    ConversationId
    Body
    SentAt
    MessageError
    MessageStatus
    ContentType
    CreatedAt
    UpdatedAt
    EventType
  }
}`;
export const newAgentChatMessageEventReceiver = `subscription newAgentChatMessageEventReceiver($InstanceId: String!, $Receiver:String!) {
  newAgentChatMessageEventReceiver(InstanceId: $InstanceId, Receiver: $Receiver) {
    InstanceId
    Author
    id
    ConversationId
    Body
    SentAt
    MessageError
    MessageStatus
    ContentType
    CreatedAt
    UpdatedAt
    EventType
    Receiver
  }
}`;

export const onDeleteUser = `subscription onDeleteUser($InstanceId: String!) {
  onDeleteUser(InstanceId: $InstanceId) {
    AWSAccountID
    InstanceId
    Id
    Username
  }
}
`;

export const onNewEmailContact = `subscription onNewEmailContact($InstanceId: String!, $Username: String!) {
  onNewEmailContact(InstanceId: $InstanceId, Username: $Username) {
    id
    InstanceId
    Messages {
      id
      Subject
    }
    CustomerEmail
    EmailFlowId
    AgentEmail
    Accepted
    AcceptedAt
    RejectedAt
  }
}
`;

export const onStatusChangeEmailContact = `subscription onStatusChangeEmailContact($InstanceId: String!, $id: String!) {
  onStatusChangeEmailContact(InstanceId: $InstanceId, id: $id) {
    InstanceId
      id
      Status
  }
}`;

export const onNewEmailMessage = `subscription onNewEmailMessage($InstanceId: String!, $ContactId: String!) {
  onNewEmailMessage(InstanceId: $InstanceId, ContactId: $ContactId) {
    id
    Author
    Subject
    AttachmentCount
    BodyText
    BodyHtml
    CreatedAt
    Direction
    ContactId
    CustomerEmail
    ForwardedMessageId
    AgentEmail
    InstanceId
    MessageId
  }
}
`;

export const onDeletedAnnouncement = `subscription OnDeletedAnnouncement($InstanceId: String) {
  onDeletedAnnouncement(InstanceId: $InstanceId) {
    id
    Message
    InstanceId
    From
    Urgency
    Queues
    Title
    Date
  }
}`;

export const onCreateUserIntegrations = `subscription onCreateUserIntegrations($InstanceId: String!) {
  onCreateUserIntegrations(InstanceId: $InstanceId)  {
        InstanceId
        Id
        FirstName
        LastName
        Email
        Username
        Platform
        StatusDuration
        StatusName
        StatusStartTimestamp
        StatusType
        UpdatedAt
        CreatedAt
        IntegrationType
        PhoneNumbers
    }
}`;

export const onDeleteUserIntegrations = `subscription onDeleteUserIntegrations($InstanceId: String!) {
  onDeleteUserIntegrations(InstanceId: $InstanceId)  {
        InstanceId
        Id
    }
}`;

export const onUpdateUserIntegrations = `subscription onUpdateUserIntegrations($InstanceId: String!) {
  onUpdateUserIntegrations(InstanceId: $InstanceId)  {
        InstanceId
        Id
        FirstName
        LastName
        Email
        Username
        Platform
        StatusDuration
        StatusName
        StatusStartTimestamp
        StatusType
        UpdatedAt
        CreatedAt
        IntegrationType
        PhoneNumbers
    }
}`;

export const onIntegrationChanged = `subscription onIntegrationChanged($InstanceId: String!) {
  onIntegrationChanged(InstanceId: $InstanceId) {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on Office365IntegrationType {
                ClientSecret
                ClientId
                TenantId
                SMTP
                Teams
                HasDelegatedSession
            }
        }
    }
}
`;

export const onIntegrationDeleted = `subscription onIntegrationDeleted($InstanceId: String!) {
  onIntegrationDeleted(InstanceId: $InstanceId)  {
        InstanceId
        Id
        Name
        Type
        Provider
        CreatedBy
        CreatedAt
        UpdatedAt
        Integration {
            __typename
            ... on SmsTroughPinpointIntegrationType {
                    ContactFlowId
                    PhoneNumber
                    PinpointAppId
                    CloudFormationUrl
                    CloudFormationStackName
            }
            __typename
            ... on Office365IntegrationType {
                ClientSecret
                ClientId
                TenantId
                SMTP
                Teams
                HasDelegatedSession
            }
        }
    }
}`;

export const newAgentChimeCallEvent = `subscription newAgentChimeCallEvent($InstanceId: String!) {
  newAgentChimeCallEvent(InstanceId: $InstanceId)  {
        MeetingId
        ExternalMeetingId
        MediaRegion
        MeetingArn
        InstanceId
        Initiator
        Receiver
        Status
        MediaPlacement {
            AudioFallbackUrl
            AudioHostUrl
            EventIngestionUrl
            ScreenDataUrl
            ScreenSharingUrl
            ScreenViewingUrl
            SignalingUrl
            TurnControlUrl
        }   
    }
}`;
