<template>
  <div>
    <div class="wrapper-loading">
      <i class="fa fa-spinner fa-spin" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.wrapper-loading {
  position: absolute;
  width: 100%;
  z-index: 100;
  height: 100%;
  top: 0px;
  text-align: center;
  margin: auto;
  background-color: rgb(0 0 0 / 63%);
  bottom: 0px;
  i {
    font-size: 50px;
    margin: auto;
    position: relative;
    top: 50%;
    color: white;
  }
}
</style>
